export function isMobile() {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return flag;
}

// eslint-disable-next-line no-extend-native
export function routerFormat(str, query, params) {
  if (!str) return;
  let s = str;
  if (typeof query === "object") {
    for (let key in query) {
      s = s.replace(new RegExp("\\{" + key + "\\}", "g"), query[key]);
    }
  }
  if (typeof params === "object") {
    for (let key in params) {
      s = s.replace(new RegExp("\\<" + key + "\\>", "g"), params[key]);
    }
  }
  return s;
}

export function formatDate(date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + '';
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str))
    }

  }
  return fmt
}
function padLeftZero(str) {
  return ('00' + str).substr(str.length)
}

export const SET_USER_INFO = "SET_USER_INFO";
export const SET_HOME_DATA = "SET_HOME_DATA";
export const SET_CURRENT_LIST = "SET_CURRENT_LIST";
export const SET_BANNER_LIST = "SET_BANNER_LIST";
export const SET_MORE_DATA = "SET_MORE_DATA";
export const SET_VIDEO_INFO = "SET_VIDEO_INFO";
export const SET_LIVE_LIST = "SET_LIVE_LIST";
export const SET_LIVE_INFO = "SET_LIVE_INFO";
export const SET_KSB_INFO = "SET_KSB_INFO";
export const SET_COURSE_INFO = "SET_COURSE_INFO";
export const SET_ACTIVE_INFO = "SET_ACTIVE_INFO";
export const SET_ACTIVE_COURSE = "SET_ACTIVE_COURSE";
export const SET_ORDER_LIST = "SET_ORDER_LIST";
export const SET_PDF = "SET_PDF";
export const SET_SHOP_DATA = "SET_SHOP_DATA";
export const SET_LECTURER_DATA = "SET_LECTURER_DATA";
export const SET_LIVE = "SET_LIVE";
export const SET_MY_COURSEMARKET = "SET_MY_COURSEMARKET";
export const RESET_VIDEO = "RESET_VIDEO";
export const SET_PC_HOME = "SET_PC_HOME";
export const SET_OVERVIEW_DATA = "SET_OVERVIEW_DATA";
export const SET_STUDY_DATA = "SET_STUDY_DATA";
export const SET_COURSE_DATA = "SET_COURSE_DATA";
export const SET_SHOP_OVERVIEW = "SET_SHOP_OVERVIEW";
export const SET_SHOP_LECTURER = "SET_SHOP_LECTURER";
export const SET_LIVE_DATA = "SET_LIVE_DATA";
export const SET_OTT_INFO = "SET_OTT_INFO";

import * as API from "@/api/index";

import {
  SET_HOME_DATA,
  SET_SHOP_DATA,
  SET_LECTURER_DATA
} from "../mutation-types";

export default {
  namespaced: true,
  state: {
    positionList: [],
    bannerList: [],
    topList: [],
    courseList: [],
    lovelyList: [],
    caseList: [],
    firstList: [],
    secondList: [],
    thirdList: [],
    shopName: null,
    shopId: null,
    shopUrl: null,
    lecturerData: {},
    positionMap: {},
    blockListMap: [],
    positionId: {
      courseListId: null,
      lovelyListId: null,
      topListId: null,
      caseListId: []
    }
  },
  mutations: {
    [SET_HOME_DATA](state, payload) {
      const { bannerList, blockListMap, positionMap, positionList } = payload;

      state.positionList = positionList;
      state.positionMap = positionMap;
      state.bannerList = bannerList;
      state.blockListMap = blockListMap;
      state.courseList = blockListMap[positionList[0]?.positionId];
      state.lovelyList = blockListMap[positionList[1]?.positionId];
      state.topList = blockListMap[positionList[2]?.positionId];
      state.caseList = blockListMap[positionList[3]?.positionId];
      state.positionId.courseListId = positionList[0]?.positionId;
      state.positionId.lovelyListId = positionList[1]?.positionId;
      state.positionId.topListId = positionList[2]?.positionId;
      state.positionId.caseListId.fourthId = positionList[3]?.positionId;
      state.positionId.caseListId = positionList?.slice(3);
    },
    [SET_SHOP_DATA](state, payload) {
      const { courseModuleList, shopInfo } = payload;
      state.firstList = courseModuleList[0];
      state.secondList = courseModuleList[1];
      state.thirdList = courseModuleList[2];
      state.bannerList = shopInfo.bannerList;
      state.shopName = shopInfo.name;
      state.shopUrl = shopInfo.shareUrl;
    },
    [SET_LECTURER_DATA](state, payload) {
      state.lecturerData = payload;
      state.shopName = payload.shopName;
      state.shopId = payload.shopId;
    }
  },
  actions: {
    getHomeData({ commit }, payload) {
      API.getHomeList(payload).then(res => {
        commit(SET_HOME_DATA, res);
      });
    },
    getShopData({ commit }, payload) {
      API.getShopList(payload).then(res => {
        commit(SET_SHOP_DATA, res);
      });
    },
    getLecturerData({ commit }, payload) {
      API.getLecturerData(payload).then(res => {
        commit(SET_LECTURER_DATA, res);
      });
    },
    acceptInvitation({ commit }, payload) {
      API.acceptInvitation(payload).then(res => {
        commit(SET_LECTURER_DATA, res);
      });
    }
  }
};

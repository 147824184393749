import { SET_PC_HOME } from "../mutation-types";
import { getPortalBanners } from "@/api/index";

export default {
  namespaced: true,
  state: {
    banners: []
  },
  mutations: {
    [SET_PC_HOME](state, payload) {
      state.banners = payload;
    }
  },
  actions: {
    async getPortalBanners({ commit }) {
      const res = await getPortalBanners({ status: 0, size: 100 });
      commit("SET_PC_HOME", res.records);
    }
  }
};

import moment from "moment";

export function formatTime(T, S) {
  if (!T) return "--";
  return moment(T).format(S);
}

export function formatDuration(T) {
  const sec = Math.floor(T);

  return Math.floor(sec / 60) + "分" + (sec % 60) + "秒";
}

// 用户角色
export function roleTypeMap(role) {
  return {
    COMPANY: "企业版",
    ORGANIZATION: "机构版"
  }[role];
}

//加入课程库liveStatus传参 根据调用接口传参
export function liveStatusMap(type) {
  return {
    live: 11,
    course: 12,
    rrs: 13,
    ott: 14
  }[type];
}

//课程类型
export function courseTypeMap(type) {
  return {
    4: "录播课",
    5: "系列课"
  }[type];
}

// 根据秒数转换成对应的时分秒
export function getHMS(time) {
  const hour =
    parseInt(time / 3600) < 10
      ? "0" + parseInt(time / 3600)
      : parseInt(time / 3600);
  const min =
    parseInt((time % 3600) / 60) < 10
      ? "0" + parseInt((time % 3600) / 60)
      : parseInt((time % 3600) / 60);
  const sec =
    parseInt((time % 3600) % 60) < 10
      ? "0" + parseInt((time % 3600) % 60)
      : parseInt((time % 3600) % 60);
  return hour + ":" + min + ":" + sec;
}

// 根据秒数转换成对应的有效时长
export function getDays(time) {
  if (time == 0) {
    return "永久";
  } else {
    const day = parseInt(time / 3600000 / 24);

    const month = parseInt(day / 30);

    const year = parseInt(month / 12);

    const hour = parseInt(time / 3600000);

    const min = parseInt((time % 3600000) / 60);

    if (year > 0) {
      return "一年以上";
    } else if (month > 0) {
      return month + "月";
    } else if (day > 0) {
      return day + "天";
    } else if (hour > 0) {
      return hour + "小时";
    } else {
      return min + "分钟";
    }
  }
}

//有效期
export function deadLine(time) {
  return {
    0: "永久",
    15768000000: "半年",
    31536000000: "一年",
    63072000000: "两年",
    94608000000: "三年"
  }[time];
}

// 上架、下架
export function shelfStatusMap(shelfStatus) {
  return {
    1: "已上架",
    2: "已下架",
    3: "已过期"
  }[shelfStatus];
}

//直播状态
export function courseStatusMap(status) {
  return {
    0: "即将开播",
    1: "直播中",
    2: "直播结束",
    3: "回放",
    4: "录播课",
    5: "系列课",
    6: "系列课", //官方系列课
    7: "系列课", //课师宝
    8: "系列课" //OTT
  }[status];
}

//课程库视频课程类型bizType对应courseType
export function bizTypeMap(bizType) {
  return {
    11: "live",
    12: "course",
    13: "rrs",
    14: "ott"
  }[bizType];
}

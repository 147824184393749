<template>
  <div>
    正在跳转。。。
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  methods: {
    ...mapActions(["loginByWx"])
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  mounted() {
    const shareCode = localStorage.getItem("share_code");
    const { code, redirect_url } = this.$route.query;
    this.loginByWx({
      code,
      redirect_url,
      shareCode
    });
  }
};
</script>

<style></style>

export default [
  {
    path: "up",
    name: "Upload",
    meta: {
      menuGroup: "course"
    },
    component: () =>
      import(/* webpackChunkName: "layout" */ "../views/Upload/index.vue")
  },
  {
    path: "home",
    name: "HomePage",
    props: {
      terminal: 2
    },
    meta: {
      title: "课程市场",
      menuGroup: "course"
    },
    component: () =>
      import(/* webpackChunkName: "layout" */ "../views/Home/Index.vue")
  },
  {
    path: "bannerlist/:id",
    props: true,
    name: "BannerList",
    meta: { menuGroup: "course", h5path: "/h5/bannerlist/<id>" },
    component: () =>
      import(/* webpackChunkName: "layout" */ "../views/Home/BannerList.vue")
  },
  {
    path: "morelist/:tab",

    meta: { menuGroup: "course", title: "课程库" },
    name: "More",
    component: () =>
      import(/* webpackChunkName: "layout" */ "../views/Home/MoreList.vue"),
    props: {
      terminal: 2
    }
  },
  {
    path: "mediacenter",
    meta: {
      menuGroup: "course",
      title: "播放",
      h5path: "/h5/media?id={id}&courseType={courseType}"
    },
    name: "Media",
    component: () =>
      import(/* webpackChunkName: "layout" */ "../views/Media/index.vue")
  },
  {
    path: "lecturer",
    meta: {
      menuGroup: "course",
      header: "ShopHead",
      h5path: "/h5/lecturer?invitationId={invitationId}"
    },
    name: "Lecturer",
    component: () =>
      import(/* webpackChunkName: "layout" */ "../views/Lecturer/Index.vue")
  },
  {
    path: "invitation_succeeded",
    meta: {
      menuGroup: "course",
      header: "ShopHead",
      h5path: "/h5/invitation_succeeded"
    },
    name: "InvitationSucceeded",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/Lecturer/Success.vue")
  },
  {
    path: "/used",
    meta: { menuGroup: "course", header: "ShopHead", h5path: "/h5/used" },
    name: "IsUsed",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/Lecturer/isUsed.vue")
  }
];

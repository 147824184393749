<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/images/homepage.png" />
  </div>
</template>

<script>
export default {
  name: "Home"
};
</script>

<style lang="scss" scoped>
.home {
  img {
    width: 100%;
  }
}
</style>

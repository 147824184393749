import * as API from "@/api/index";
import { SET_MORE_DATA } from "../mutation-types";

export default {
  state: {
    list: [],
    pageSize: 12,
    pages: 0
  },
  mutations: {
    [SET_MORE_DATA](state, payload) {
      const { records, pages } = payload;
      state.list = records;
      state.pages = pages;
    }
  },
  actions: {
    getMoreData({ commit, state }, payload) {
      API.getCaseList(
        payload.active,
        payload.ord,
        payload.page,
        state.pageSize,
        payload.terminal
      ).then(res => {
        commit(SET_MORE_DATA, res);
      });
    }
  }
};

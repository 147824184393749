import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import home from "./modules/home";
import userCenter from "./modules/userCenter";
import banner from "./modules/banner";
import more from "./modules/more";
import media from "./modules/media";
import pchome from "./modules/pchome";
import dashboard from "./modules/dashboard";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    home,
    userCenter,
    banner,
    more,
    media,
    pchome,
    dashboard
  }
});

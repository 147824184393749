import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
// import PcHome from "../views/PcHome/PcHome.vue";
import WX from "../views/Login/WX";
import store from "../store";
import { isMobile, routerFormat } from "../utils/index";
import coursemarket from "./coursemarket";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "pc_home",
    // component: PcHome
    component: () =>
      import(/* webpackChunkName: "layout" */ "../views/layout/Index.vue"),
    children: [
      {
        path: "/",
        name: "rdhy_home",
        component: () =>
          import(/* webpackChunkName: "dashbord" */ "../views/Home/Index.vue"),
        props: {
          terminal: 3
        }
      },
      {
        path: "bannerlist/:id",
        props: true,
        name: "rdhyBannerList",
        meta: { menuGroup: "course", h5path: "/h5/bannerlist/<id>" },
        component: () =>
          import(
            /* webpackChunkName: "layout" */ "../views/Home/BannerList.vue"
          )
      },
      {
        path: "morelist/:tab",

        meta: { menuGroup: "course", title: "课程库" },
        name: "rdhyMore",
        component: () =>
          import(/* webpackChunkName: "layout" */ "../views/Home/MoreList.vue"),
        props: {
          terminal: 3
        }
      },
      {
        path: "mediacenter",
        meta: {
          menuGroup: "course",
          title: "播放",
          h5path: "/h5/media?id={id}&courseType={courseType}"
        },
        name: "rdhyMedia",
        component: () =>
          import(/* webpackChunkName: "layout" */ "../views/Media/index.vue")
      }
    ]
  },
  {
    path: "/app_home",
    name: "AppHome",
    component: Home
  },
  {
    path: "/wx",
    name: "WeixinLogin",
    component: WX
  },
  {
    path: "/login",
    name: "Login",
    meta: {},
    component: () =>
      import(/* webpackChunkName: "layout" */ "../views/Login/Index.vue")
  },
  {
    path: "/verify_phone",
    name: "VerifyPhone",
    meta: {},
    component: () =>
      import(/* webpackChunkName: "layout" */ "../views/Login/VerifyPhone.vue")
  },
  {
    path: "/change_role",
    name: "changeRole",
    component: () =>
      import(
        /* webpackChunkName: "dashbord" */ "../views/Dashboard/ChangeRole.vue"
      )
  },
  {
    path: "/dashboard",
    name: "Layout",
    redirect: "index",
    component: () =>
      import(/* webpackChunkName: "layout" */ "../views/Dashboard/layout.vue"),
    children: [
      {
        path: "/index",
        name: "dashbord",
        meta: {
          title: "企业概览",
          menuGroup: "index"
        },
        component: () =>
          import(
            /* webpackChunkName: "dashbord" */ "../views/Dashboard/QyIndex.vue"
          )
      },
      {
        path: "/price",
        name: "price",
        meta: {
          title: "付费版本介绍",
          menuGroup: "index"
        },
        component: () =>
          import(/* webpackChunkName: "dashbord" */ "../views/Price/Price.vue")
      },
      {
        path: "/employee",
        name: "employee",
        meta: {
          title: "员工信息",
          menuGroup: "employee"
        },
        component: () =>
          import(
            /* webpackChunkName: "dashbord" */ "../views/Staff/users/Index.vue"
          )
      },
      {
        path: "/employeeData",
        name: "employeeData",
        meta: {
          title: "员工数据",
          menuGroup: "employee"
        },
        component: () =>
          import(
            /* webpackChunkName: "dashbord" */ "../views/Staff/employeeData/Index.vue"
          )
      },
      {
        path: "/department",
        name: "department",
        meta: {
          title: "部门管理",
          menuGroup: "employee"
        },
        component: () =>
          import(
            /* webpackChunkName: "dashbord" */ "../views/Staff/department/Index.vue"
          )
      },
      {
        path: "/position",
        name: "position",
        meta: {
          title: "职位管理",
          menuGroup: "employee"
        },
        component: () =>
          import(
            /* webpackChunkName: "dashbord" */ "../views/Staff/position/Index.vue"
          )
      },

      {
        path: "/coursemarket",
        name: "coursemarket",
        redirect: "/coursemarket/home",
        meta: {
          title: "课程市场",
          menuGroup: "course"
        },
        component: () =>
          import(
            /* webpackChunkName: "dashbord" */ "../views/Dashboard/Coursemarket.vue"
          ),

        children: coursemarket
      },
      {
        path: "/courselib",
        name: "courselib",
        meta: {
          title: "企业课程库",
          menuGroup: "course"
        },
        component: () =>
          import(/* webpackChunkName: "dashbord" */ "../views/Course/Index.vue")
      },
      {
        path: "/shopbuild",
        name: "shopbuild",
        meta: {
          title: "主页装修",
          menuGroup: "series_course"
        },
        component: () =>
          import(
            /* webpackChunkName: "dashbord" */ "../views/ShopBuild/ShopBuild.vue"
          )
      },
      {
        path: "/organization_index",
        name: "organizationDashbord",
        meta: {
          title: "账户概览",
          menuGroup: "organizationIndex"
        },
        component: () =>
          import(
            /* webpackChunkName: "dashbord" */ "../views/Dashboard/JgIndex.vue"
          )
      },
      {
        path: "/prices",
        name: "prices",
        meta: {
          title: "付费版本介绍",
          menuGroup: "organizationIndex"
        },
        component: () =>
          import(
            /* webpackChunkName: "dashbord" */ "../views/Price/PriceOfOrganization.vue"
          )
      },
      {
        path: "/series_course",
        name: "seriesCourse",
        meta: {
          title: "系列课管理",
          menuGroup: "series_course"
        },
        component: () =>
          import(
            /* webpackChunkName: "dashbord" */ "../views/CourseList/SeriesCourseList/Index.vue"
          )
      },
      {
        path: "/series_sub_course",
        name: "seriesSubCourse",
        meta: {
          title: "系列课子课程管理",
          menuGroup: "series_course",
          hidden: true
        },
        component: () =>
          import(
            /* webpackChunkName: "dashbord" */ "../views/CourseList/SeriesCourseList/subCourse.vue"
          )
      },
      {
        path: "/broadcast_course",
        name: "broadcastCourse",
        meta: {
          title: "录播课管理",
          menuGroup: "series_course"
        },
        component: () =>
          import(
            /* webpackChunkName: "dashbord" */ "../views/CourseList/BroadcastCourseList/Index.vue"
          )
      },
      {
        path: "/live_course",
        name: "liveCourse",
        meta: {
          title: "直播课管理",
          menuGroup: "series_course"
        },
        component: () =>
          import(
            /* webpackChunkName: "dashbord" */ "../views/CourseList/LiveCourseList/Index.vue"
          )
      }
    ]
  },
  {
    path: "/shop",
    name: "ShopLayout",
    redirect: "index",
    component: () =>
      import(/* webpackChunkName: "layout" */ "../views/layout/Index.vue"),
    children: [
      {
        path: "",
        name: "Shop",
        meta: {
          header: "ShopHead",
          h5path: "/h5/shop?shopId={shopId}"
        },
        component: () =>
          import(/* webpackChunkName: "layout" */ "../views/Home/ShopIndex.vue")
      },
      {
        path: "shop_mediacenter",
        meta: {
          menuGroup: "course",
          header: "ShopHead",
          h5path: "/h5/shopMedia?id={id}&courseType={courseType}"
        },
        name: "ShopMedia",
        component: () =>
          import(/* webpackChunkName: "layout" */ "../views/Media/index.vue")
      },
      {
        path: "usercenter",
        name: "UserCenter",
        meta: {
          header: "ShopHead"
        },
        component: () =>
          import(
            /* webpackChunkName: "layout" */ "../views/UserCenter/Index.vue"
          ),
        children: [
          {
            path: "/recentStudy",
            name: "RecentStudy",
            meta: {
              header: "ShopHead"
            },
            component: () =>
              import(
                /* webpackChunkName: "layout" */ "../views/UserCenter/Recent.vue"
              )
          },
          {
            path: "/purchase",
            name: "Purchase",
            meta: {
              header: "ShopHead"
            },
            component: () =>
              import(
                /* webpackChunkName: "layout" */ "../views/UserCenter/Purchase.vue"
              )
          }
        ]
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
router.afterEach(() => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
});

router.beforeEach((to, from, next) => {
  let share_code = to.query.share_code;
  let theme = to.meta.theme;
  if (theme) {
    let htmlElement = document.documentElement;
    htmlElement.setAttribute("theme", theme);
  }
  if (share_code) {
    // store.dispatch("setShareCodeName", share_code);
    localStorage.setItem("share_code", share_code);
  }
  if (isMobile()) {
    let href = to.meta.h5path || "/h5";
    if (share_code) {
      href =
        href + `${href.indexOf("?") > -1 ? "&" : "?"}share_code=${share_code}`;
    }
    window.location.href =
      window.location.origin + routerFormat(href, to.query, to.params);
  } else {
    next();
  }
  if (store.getters.isLogin) {
    if (to.path == "/wx") {
      window.location.href = window.location.origin;
    }
  }
});
export default router;

import Router from "vue-router";

Vue.use(Router);
// 解决报错
const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;
// push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
// replace
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};

import {
  SET_OVERVIEW_DATA,
  SET_STUDY_DATA,
  SET_COURSE_DATA,
  SET_SHOP_OVERVIEW
} from "../mutation-types";
import {
  getEmployeeData,
  getStudyDetail,
  getOranizationCourseList,
  getShopData
} from "@/api/dashboard";

export default {
  namespaced: true,
  state: {
    data: {
      accountNum: 0, //员工账号总数
      avgStudyDurationStr: "", //平均学习时长
      dayStudyDurationStr: "", //今日学习时长
      dayStudyEmployeeNum: 0 //今日学习员工数量
    },
    shopData: {
      courseTotal: 0, //课程总数
      payerTotal: 0, //累计付款人数
      visitorNewToday: 0, //今日新访客数
      visitorToday: 0, //今日访客数
      visitorTotal: 0 //累计访客数
    },
    studyData: [],
    total: 0,
    courseData: []
  },
  mutations: {
    [SET_OVERVIEW_DATA](state, payload) {
      state.data = payload;
    },
    [SET_STUDY_DATA](state, payload) {
      state.studyData = payload.records;
      state.total = payload.total;
    },
    [SET_COURSE_DATA](state, payload) {
      state.courseData = payload.records;
      state.total = payload.total;
    },
    [SET_SHOP_OVERVIEW](state, payload) {
      state.shopData = payload;
    }
  },
  actions: {
    async getDashboardData({ commit }, payload) {
      const res = await getEmployeeData(payload);
      commit("SET_OVERVIEW_DATA", res);
    },
    async getStudyDetailData({ commit }, payload) {
      const res = await getStudyDetail(payload);
      commit("SET_STUDY_DATA", res);
    },
    async getDashboardShopData({ commit }) {
      const res = await getShopData();
      if (res.statistics !== null) {
        commit("SET_SHOP_OVERVIEW", res.statistics);
      }
    },
    async getCourseData({ commit }, payload) {
      const res = await getOranizationCourseList(payload);
      commit("SET_COURSE_DATA", res);
    }
  }
};

import * as API from "@/api/index";
import * as API2 from "@/api/dashboard";
import {
  SET_VIDEO_INFO,
  SET_LIVE_INFO,
  SET_KSB_INFO,
  SET_COURSE_INFO,
  SET_ACTIVE_INFO,
  SET_ACTIVE_COURSE,
  SET_PDF,
  SET_LIVE,
  SET_MY_COURSEMARKET,
  RESET_VIDEO,
  SET_OTT_INFO
} from "../mutation-types";

const defaultState = {
  state: 0,
  pdfInfo: [], //资料
  userinfo: {},
  videoInfo: {
    liveEntity: {
      liveScreenStatus: 0 // 0横板 1竖版
    },
    streamUrl: {},
    userAuthVO: {},
    verticalScreen: false,
    shopInfo: {}
  },
  activeVideo: {}, //课程信息（了解我）
  streamInfo: {},
  thirdPartResourceInfo: {}, //第三方课师宝
  activeItemVideo: {},
  courseInfo: {}, //官方系列课
  activeCourse: {}, //课程表
  activeInfo: {}, //了解我
  live: false,
  myCourseMarket: false //是否在我的课程库内
};
export default {
  namespaced: true,
  state: {
    ...defaultState
  },
  mutations: {
    [SET_LIVE](state, payload) {
      state.live = payload;
    },
    [SET_MY_COURSEMARKET](state, payload) {
      state.myCourseMarket = payload;
    },
    //用户课程播放地址
    [SET_VIDEO_INFO](state, payload) {
      state.videoInfo = payload;
    },
    //用户课程（APP）
    [SET_LIVE_INFO](state, payload) {
      state.activeVideo = payload;
    },
    //了解我
    [SET_ACTIVE_INFO](state, payload) {
      state.activeInfo = payload;
    },
    //课程表
    [SET_ACTIVE_COURSE](state, payload) {
      state.activeCourse = payload;
    },
    //官方系列课
    [SET_COURSE_INFO](state, payload) {
      state.activeVideo = payload;
      state.activeInfo = payload; //了解我
    },
    //第三方
    [SET_KSB_INFO](state, payload) {
      state.activeVideo = payload;
      state.activeInfo = payload; //了解我
    }, //OTT
    [SET_OTT_INFO](state, payload) {
      state.activeVideo = payload;
      state.activeInfo = payload; //了解我
    },
    //OTT
    [SET_OTT_INFO](state, payload) {
      state.activeVideo = payload;
      state.activeInfo = payload; //了解我
    },
    //资料
    [SET_PDF](state, payload) {
      state.pdfInfo = payload;
    },
    [RESET_VIDEO](state) {
      state.state = 0;
      state.pdfInfo = [];
      state.userinfo = {};
      state.videoInfo = {
        liveEntity: {
          liveScreenStatus: 0 // 0横板 1竖版
        },
        streamUrl: {},
        userAuthVO: {},
        verticalScreen: false
      };
      state.activeVideo = {};
      state.streamInfo = {};
      state.liveList = [];
      state.thirdPartResourceInfo = {};
      state.activeItemVideo = {};
      state.courseInfo = {};
      state.activeCourse = {};
      state.activeInfo = {};
      state.live = false;
      state.myCourseMarket = false;
    }
  },
  actions: {
    getPwd({ commit }, id) {
      API.getPwd(id).then(res => {
        commit("SET_LIVE_INFO", res);
      });
    },
    getVideoChapter({ commit }, id) {
      API.getStreamDetail(id).then(res => {
        commit("SET_VIDEO_INFO", res); //播放地址
      });
    },
    getVideo({ commit }, id) {
      commit("SET_LIVE", false);
      API.getStreamDetail(id).then(res => {
        // commit("SET_LIVE", true);
        // 0:即将开播 1:直播中 2:直播结束 3:回放 4:录播课 5:系列课
        const status = res.liveEntity.liveStatus; //课程状态
        const liveSeries = res.liveEntity.liveSeries; //系列课id
        commit("SET_LIVE_INFO", res.liveEntity); //播放器信息

        if (status == 0) {
          //即将开播显示倒计时
          const now = new Date().getTime();
          const begin = res.liveEntity.liveBeginTime;
          let diff = (begin - now) / 1000;
          if (diff > 10) {
            res.liveEntity.deadTime = diff;
          }
        } else if (status == 1) {
          if (res.liveEntity.livePushTime) {
            res.streamUrl.videoSource = res.streamUrl.m3u8_lhd;
          }
          window.init();
        }
        commit("SET_VIDEO_INFO", res); //播放地址

        //用户直播课（回放）、录播课可以收录到系列课的子课程
        if (liveSeries) {
          API.getStreamDetail(liveSeries).then(response => {
            commit("SET_ACTIVE_INFO", response.liveEntity); //了解我
            commit("SET_ACTIVE_COURSE", response.liveList); //课程表
            commit("SET_MY_COURSEMARKET", response.myCourseMarket);
          });
        } else {
          commit("SET_ACTIVE_INFO", res.liveEntity); //了解我
          commit("SET_MY_COURSEMARKET", res.myCourseMarket);
        }

        //系列课课程表
        if (res.liveList) {
          commit("SET_ACTIVE_COURSE", res.liveList);
          API.getStreamDetail(res.liveList[0].id).then(response => {
            commit("SET_VIDEO_INFO", response);
          });
        }
        commit("SET_LIVE", true);
      });
    },
    getCourseData({ commit }, payload) {
      commit("SET_LIVE", false);
      API.getCourseList(payload).then(res => {
        commit("SET_COURSE_INFO", res.courseInfo);
        commit("SET_MY_COURSEMARKET", res.myCourseMarket);
        commit("SET_LIVE", true);
        if (res.courseInfo.chaptersList) {
          commit("SET_ACTIVE_COURSE", res.courseInfo.chaptersList[0]);
        }
      });
    },
    getRrdData({ commit }, payload) {
      commit("SET_LIVE", false);
      API.getRrdList(payload).then(res => {
        commit("SET_KSB_INFO", res.thirdPartResourceInfo);
        commit("SET_MY_COURSEMARKET", res.myCourseMarket);
        commit("SET_LIVE", true);
        if (res.thirdPartResourceInfo.chaptersList) {
          commit(
            "SET_ACTIVE_COURSE",
            res.thirdPartResourceInfo.chaptersList[0]
          );
        }
      });
    },
    getOttData({ commit }, payload) {
      commit("SET_LIVE", false);
      API.getOttList(payload).then(res => {
        commit("SET_OTT_INFO", res);
        // commit("SET_MY_COURSEMARKET", res.myCourseMarket);
        commit("SET_MY_COURSEMARKET", res.myCourseMarket);
        commit("SET_LIVE", true);
        if (res.chapters) {
          commit("SET_ACTIVE_COURSE", res.chapters[0]);
        }
      });
    },
    getPdf({ commit }, payload) {
      API.getPdf(payload.id, payload.courseType).then(res => {
        commit(SET_PDF, res);
      });
    },
    addCourseLib({ commit }, payload) {
      console.log("11111111111111");
      API2.addCourseLib(payload).then(res => {
        // await addCourseLib({ id, liveStatus });
        // this.$message.success("添加成功");
        console.log("res", res);
        commit("SET_MY_COURSEMARKET", true);
      });
    }
  }
};

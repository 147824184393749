import http from "./http";

export const getDepartments = params =>
  http.get("/api/company/department", { params });
export const addDepartments = data =>
  http.post("/api/company/department", data);
export const editDepartments = data =>
  http.put("/api/company/department", data);
export const delDepartments = params =>
  http.delete("/api/company/department", { params });

export const getEmployee = params =>
  http.get("/api/company/employee", { params });
export const addEmployee = data => http.post("/api/company/employee", data);
export const editEmployee = data => http.put("/api/company/employee", data);
export const delEmployee = params =>
  http.delete("/api/company/employee", { params });
export const getEmployeeNum = () => http.get("/api/company/employee/number");

export const getPosition = params =>
  http.get("/api/company/position", { params });
export const addPosition = data => http.post("/api/company/position", data);
export const editPosition = data => http.put("/api/company/position", data);
export const delPosition = params =>
  http.delete("/api/company/position", { params });

//员工数据总览
export const getEmployeeData = params =>
  http.get("/api/company/employee/data/overview", { params });

//员工学习时长
export const getStudyDuration = params =>
  http.get("/api/company/employee/data/daily", { params });

//员工学习时长详情
export const getStudyDetail = params =>
  http.get("/api/company/employee/data/detail", { params });

// 课程库栏目
export const getCourseType = params =>
  http.get("/api/company/course/pc", { params });

//删除课程库视频
export const delCourseType = params =>
  http.delete("/api/company/course/market", { params });

// 上架课程库
export const courseTypeshelfSwitch = data =>
  http.post("/api/company/course/shelf/switch", data);

// 课程列表
export const getCourse = params =>
  http.get("/api/company/course/pc/video", { params });

// 加入课程库
export const addCourseLib = params =>
  http.post("/api/company/course/pc/video", null, { params });

// 用户角色切换
export const changeUserRole = params =>
  http.put("/api/user/role/select", null, { params });

// 获取课程主页
export const getShopInfo = params => http.get("/api/shop/info", { params });

// 更新店铺
export const upShopInfo = data => http.put("/api/shop/update", data);

//  获取课程列表
// courseType 课程类型 3直播课 4录播课 5系列课
// shelfStatus 上下架状态：1上架，2下架
export const getCourseList = params =>
  http.get("/api/live/course/page", { params });

// 编辑课程(包括系列课和录播课)
export const editCourse = data => http.post("/api/live/course/edit", data);

// 获取课程
export const getCourseInfo = id => http.get(`/api/live/course/${id}`);

// 创建系列课
export const addCourse = data => http.post("/api/live/createSeries", data);

// 删除系列课
export const delSeries = id => http.delete(`/api/live/delSeries/${id}`);

// 删除录播课和直播课
export const deleteLive = id => http.delete(`/api/live/deleteLive/${id}`);

// 创建系列课子课程
export const addSubCourse = data =>
  http.post(`/api/live/series/sub-course/add`, data);

// 删除系列课子课程
export const delSubCourse = courseId =>
  http.delete(`/api/live/series/sub-course/delete`, { params: { courseId } });

// 编辑系列子课程
export const editSubCourse = data =>
  http.post(`/api/live/series/sub-course/edit`, data);

// 查询系列课子课程
// export const getSubCourse = id => http.get(`/api/live/series/${id}`);
export const getSubCourse = params =>
  http.get(
    "/api/live/series/" +
      params.id +
      (params.keyword == "" || params.keyword == null
        ? "?"
        : "?keyword=" + params.keyword + "&") +
      "current=" +
      params.current +
      "&" +
      "size=" +
      params.size
  );

// 创建录播课
export const uploadVideo = data => http.post(`/api/live/uploadVideo`, data);

//账户概览课程列表
export const getOranizationCourseList = params =>
  http.get("/api/live/course/page", { params });

//账户概览课程编辑
export const updateOranizationCourse = data =>
  http.post("/api/live/course/edit", data);

// 上架课程
export const shelfSwitch = data =>
  http.put("/api/shop/course/shelf/switch", data);

//账户概览数据统计
export const getShopData = () => http.get("/api/shop/info");

//机构课程拖动排序
export const sortCourse = data => http.post("/api/live/course/sort", data);

//编辑直播课
export const editLiveCourse = data => http.post("/api/live/editLive", data);

// 第一步：创建直播课
export const addLiveCourse = data => http.post("/api/live/generateLive", data);

//第二步：选择直播方式
export const selectLiveWay = data => http.post("/api/live/selectLiveWay", data);

//直播课详情
export const getLiveCourseDetail = params =>
  http.get("/api/live/detail", null, { params });

//获取流地址
export const getstreamUrl = id => http.get(`/api/live/streamUrl/${id}`);

//退出观看时记录学习时长
export const durationRecord = data => http.post("/api/live/leave", data);

//发起直播讲师邀请(链接或者二维码的方式)
export const lecturerInvitation = params =>
  http.post("/api/live/lecturer/invite", null, { params });

//发送讲师邀请
export const sendLecturerInvitation = data =>
  http.post("/api/shop/lecturer/invitation", data);

//查询店铺讲师列表
export const getShopLecturerList = () => http.get("/api/shop/lecturer/list");

//根据手机号获取讲师
export const getLecturerByPhone = (mobile, id) =>
  http.get(`/api/live/lecturer/queryByMobile?mobile=${mobile}&liveId=${id}`);

//根据发起人账户ID查询最近添加讲师记录
export const getLecturerLogById = id =>
  http.get(`/api/live/lecturer/listLog?inviterUserId=${id}`);

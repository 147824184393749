import * as API from "@/api/index";
import router from "@/router/index";
import { SET_USER_INFO } from "../mutation-types";
export default {
  state: {
    userInfo: null
  },
  mutations: {
    [SET_USER_INFO](state, payload) {
      state.userInfo = payload;
      window.localStorage.userInfo = JSON.stringify(payload);
    }
  },
  actions: {
    // setShareCodeName({ commit }, name) {
    //   commit("setShareCode", name);
    // },
    loginByWx({ commit }, payload) {
      API.loginByWx(payload.code, 1, payload.shareCode).then(res => {
        commit(SET_USER_INFO, res);
        localStorage.removeItem("share_code");
        const { mobile } = res;
        console.log("登录成功:", window.atob(payload.redirect_url));
        //TODO
        if (!mobile) {
          // router.push({ name: "VerifyPhone" }); /** 不绑定手机号 */
          router.push(window.atob(payload.redirect_url));
        } else {
          router.push(window.atob(payload.redirect_url));
        }
      });
    },
    async getUserInfo({ commit }) {
      const res = await API.getUserInfo();
      commit(SET_USER_INFO, res);
    },
    loginOut({ commit }) {
      localStorage.removeItem("userInfo");
      commit(SET_USER_INFO, "");
      // router.push("/login");
    },
    loginBySms({ commit }, payload) {
      API.loginByTel(payload.phone, payload.sms, payload.shareCode).then(
        res => {
          commit(SET_USER_INFO, res);
          localStorage.removeItem("share_code");
          router.push(window.atob(payload.redirect_url));
        }
      );
    },
    confirmPhone({ state }, payload) {
      console.log(state);
      console.log("payload", payload.redirect_url);
      API.wechatLogin(payload)

        .then(() => {
          localStorage.removeItem("share_code");
          router.push(payload.redirect_url ? atob(payload.redirect_url) : "/");
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  getters: {
    userInfo: state => {
      const local = window.localStorage.userInfo;
      const userInfo = state.userInfo || (local && JSON.parse(local));
      return userInfo;
    },
    isLogin: (state, getters) => {
      // return getters.userInfo && getters.userInfo.mobile; /** 取消手机号验证 */
      return getters.userInfo && getters.userInfo.id; /** 取消手机号验证 */
    }
  }
};

import * as API from "@/api/index";
import { SET_BANNER_LIST } from "../mutation-types";

export default {
  namespaced: true,
  state: {
    banner: {},
    live: [],
    size: 12,
    orderCol: 1,
    pages: 0
  },
  mutations: {
    [SET_BANNER_LIST](state, payload) {
      const { bannerInfo, coursePage } = payload;
      state.banner = bannerInfo;
      state.live = coursePage != null ? coursePage.records : [];
      state.pages = coursePage != null ? coursePage.pages : 0;
    }
  },
  actions: {
    getBannerData({ commit, state }, payload) {
      API.getBannerList(
        payload.id,
        payload.ord,
        payload.page,
        state.size,
        payload.terminal
      ).then(res => {
        commit(SET_BANNER_LIST, res);
      });
    }
  }
};

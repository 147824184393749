import * as API from "@/api/index";
import { SET_CURRENT_LIST, SET_ORDER_LIST } from "../mutation-types";

export default {
  state: {
    livePersonalPageVOList: [],
    pages: 0,
    pageSize: 12,
    orders: []
  },
  mutations: {
    [SET_CURRENT_LIST](state, payload) {
      const { records, pages } = payload;
      state.livePersonalPageVOList = records;
      state.pages = pages;
    },
    [SET_ORDER_LIST](state, payload) {
      const { orders, page } = payload;
      state.orders = orders;
      state.pages = page;
    }
  },
  actions: {
    getCurrentData({ commit, state }, current) {
      API.getCurrent(current, state.pageSize).then(res => {
        commit(SET_CURRENT_LIST, res);
      });
    },
    getOrderData({ commit, state }, page) {
      API.getUserOrder(state.pageSize, page).then(res => {
        commit(SET_ORDER_LIST, res);
      });
    }
  }
};

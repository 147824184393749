import axios from "axios";
import router from "../router";
import store from "../store/index";
import { Message } from "element-ui";
// import { wxLogin } from "../utils/wx"
// axios.defaults.headers.post["Content-Type"] =
//   "application/x-www-form-urlencoded; charset=UTF-8";
// axios.defaults.headers["token"] =
//   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiI4MTA0NCIsImlhdCI6MTU5MzYxODQ3M30.r7MX-gNY20iDgQgdxqL7-k7jGvvW64m6xY8kMddUJQA" ||
//   window.localStorage.token ||
//   "";

const httpClient = axios.create({
  baseURL: "/",
  timeout: 100000,
  headers: {
    version: "2.2.6",
    client: "pc"
  }
});
httpClient.interceptors.request.use(
  config => {
    config.headers.token =
      store.getters.isLogin && store.getters.userInfo.token;
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

httpClient.interceptors.response.use(
  response => {
    // console.log("response", response);
    if (!response.data) {
      Message.error("服务器错误");
      return Promise.reject(null);
    }

    if (response.data.code === 666 || response.data.code === 401) {
      const redirect_route = router.history.current.fullPath;
      if (redirect_route.slice(1, 5) == "shop") {
        router.replace({
          path: "/login",
          query: {
            redirect_route: router.history.current.fullPath
            // query: "/mediacenter" + location.href.split("/mediacenter")[1]
          }
        });
      } else {
        router.replace({
          path: "/login"
        });
      }

      store.dispatch("loginOut");
      return {};
    }
    if (response.data.code === 200) {
      return response.data.data;
    } else {
      Message.error(response.data.msg);
      return Promise.reject(null);
    }
  },
  err => {
    return Promise.reject(err);
  }
);

export default httpClient;
